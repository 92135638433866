import {ExperimentsBag} from '@wix/wix-experiments'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const isNewCheckoutDropdownsEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.NewCheckoutDropdowns)

export const isShowCouponInputInPaymentStepEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.ShowCouponInputInPaymentStep)

export const isSelectEventsManuallyEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.SelectEventsManually)
